import { renderHtml } from 'utils/renderHelpers';
import classNames from 'classnames/bind';
import styles from './HTMLContent.module.scss';

const cx = classNames.bind(styles);

export default ({ html, Tag = 'div', columns = 2, classNames = '', style }) => {
  let classes = null;
  let classObj = {};
  if (classNames) {
    classes = classNames.split(' ');
    classes.forEach(c => {
      classObj[c] = true;
    });
  }
  return renderHtml(
    html,
    Tag,
    cx({ content: true, [`cols${columns}`]: true, ...classObj }),
    { style: { ...style } }
  );
};
