import Columns from "react-bulma-components/lib/components/columns";
import HTMLContent from "slices/HTMLContent";
import React from "react";
import classNames from "classnames/bind";
import { renderFluidImage } from "utils/imageHelpers";
import styles from "./ContentBlock.module.scss";
import uid from "utils/uid";

const cx = classNames.bind(styles);

function ColumnRenderer({ isContentConstrained, children }) {
  return isContentConstrained ? (
    <Columns multiline>{children}</Columns>
  ) : (
    <>{children}</>
  );
}

function ItemRenderer({ isContentConstrained, columns, children }) {
  return isContentConstrained ? (
    <Columns.Column
      mobile={{ size: 12 }}
      tablet={{
        size: isContentConstrained ? (columns >= 3 ? 6 : 12 / columns) : 12,
      }}
      desktop={{ size: isContentConstrained ? 12 / columns : 12 }}
    >
      {children}
    </Columns.Column>
  ) : (
    <>{children}</>
  );
}

export default ({
  id,
  primary,
  items,
  size = { mobile: 12, tablet: 12, desktop: 12, widescreen: 12, fullhd: 12 },
  contentStyle = {},
}) => {
  let isContentConstrained =
    primary.column_distribution ===
    `Each content block is constrained to its own column.`;

  return (
    <Columns.Column
      mobile={{ size: size.mobile }}
      tablet={{ size: size.tablet }}
      desktop={{ size: size.desktop }}
      widescreen={{ size: size.widescreen }}
      fullhd={{ size: size.fullhd }}
    >
      <HTMLContent
        columns={isContentConstrained ? 1 : primary.columns || 1}
        html={primary.heading}
      />
      <ColumnRenderer isContentConstrained={isContentConstrained}>
        {items.map((item, idx) => (
          <ItemRenderer
            key={`${id}-item-${idx}`}
            isContentConstrained={isContentConstrained}
            columns={parseInt(primary.columns) || 1}
          >
            {item.image && item.image.url && item.image.dimensions ? (
              <div
                className={cx({
                  imageWithText: true,
                  padBottom: true,
                })}
              >
                <figure
                  className={cx({ image: true })}
                  style={{
                    width: item.image.dimensions.width,
                  }}
                >
                  {renderFluidImage(item.image)}
                </figure>
                <HTMLContent
                  key={`${id}-${idx}`}
                  html={item.content}
                  columns={isContentConstrained ? 1 : primary.columns || 1}
                  style={{ paddingRight: 20 }}
                />
              </div>
            ) : (
              item.content && (
                <HTMLContent
                  key={`${id}-${idx}`}
                  html={item.content}
                  columns={
                    isContentConstrained ? 1 : parseInt(primary.columns) || 1
                  }
                  style={
                    !isContentConstrained && primary.columns <= 1
                      ? {
                          maxWidth: `85ch`,
                          margin: `0 auto`,
                          ...contentStyle,
                        }
                      : null
                  }
                />
              )
            )}
          </ItemRenderer>
        ))}
      </ColumnRenderer>
    </Columns.Column>
  );
};
