import React from 'react';
import Section from 'react-bulma-components/lib/components/section';
import Columns from 'react-bulma-components/lib/components/columns';
import Container from 'react-bulma-components/lib/components/container';
import { renderFluidImage } from 'utils/imageHelpers';
import classNames from 'classnames/bind';
import styles from './FullWidthImage.module.scss';

const cx = classNames.bind(styles);

export const ImageColumns = ({ primary }) => (
  <React.Fragment>
    {primary.images.map(image => (
      <Columns.Column
        key={image.url}
        mobile={{ size: 12 }}
        tablet={{ size: 6 }}
      >
        {renderFluidImage(image)}
      </Columns.Column>
    ))}
  </React.Fragment>
);

export const ImageColumn = ({ primary, tablet = 12 }) => (
  <Columns.Column mobile={{ size: 12 }} tablet={{ size: tablet }}>
    {renderFluidImage(primary.image)}
  </Columns.Column>
);

export default ({ primary }) => {
  const theme = primary && primary.theme ? primary.theme : 'white';
  return (
    <Section className={`${theme} ${cx({ section: true, [theme]: true })}`}>
      <Container>
        <Columns mobile>
          <ImageColumn primary={primary} />
        </Columns>
      </Container>
    </Section>
  );
};
